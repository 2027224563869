@use "../variables/color";

.block.card-grid {
   > .title {
      @apply font-roboto_slab;
      @apply text-gray-1;
      font-size: 36px;
      font-weight: 500;
      line-height: 37px;
      margin-bottom: 30px;
   }

   .cards {
      .card {
         background-color: #fff;
         box-shadow: 0px 0px 10px -4px rgb(0 0 0 / 40%);
         padding: 20px;

         .title {
            @apply font-roboto_slab;
            @apply text-orange-1;

            font-size: 15px;
            font-weight: 600;
            line-height: 1.3em;
            margin-bottom: 10px;
         }

         .text {
            @apply text-gray-3;
            font-size: 14px;
            font-weight: 400;
            line-height: 1.3em;
         }
      }
   }
}
