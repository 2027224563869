@use "../variables/color";

.block.text-grid {
   .container {
      padding-block: 50px;
      color: #fff;

      > .title {
         @apply font-roboto_slab;
         @apply text-gray-1;
         font-size: 36px;
         font-weight: 500;
         line-height: 37px;
         margin-bottom: 50px;
         color: #fff;
      }

      .items {
         counter-reset: item;

         .item {
            counter-increment: item;

            .title {
               @apply font-roboto_slab;
               font-size: 23px;
               font-weight: 600;
               line-height: 1.3em;
               margin-bottom: 10px;

               &::before {
                  content: counter(item) ". ";
               }
            }

            .text {
               font-size: 14px;
               font-weight: 400;
               line-height: 1.3em;
            }
         }
      }
   }
}
