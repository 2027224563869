.bg-palette-blue-1,
.has-palette-blue-1-background-color {
   @apply bg-blue-1;

   .gform_confirmation_message {
      @apply text-white;
   }
}

.bg-palette-blue-2,
.has-palette-blue-2-background-color {
   @apply bg-blue-2;

   .gform_confirmation_message {
      @apply text-white;
   }
}

.bg-palette-orange-1,
.has-palette-orange-1-background-color {
   @apply bg-orange-1;

   .gform_confirmation_message {
      @apply text-white;
   }
}

.bg-palette-gray-1,
.has-palette-gray-1-background-color {
   @apply bg-gray-1;
}

.bg-palette-gray-2,
.has-palette-gray-2-background-color {
   @apply bg-gray-3;
}

.bg-palette-gray-3,
.has-palette-gray-3-background-color {
   @apply bg-gray-3;
}

.bg-palette-gray-4,
.has-palette-gray-4-background-color {
   @apply bg-gray-4;
}

.bg-palette-white,
.has-palette-white-background-color {
   @apply bg-white;
}

// .text-palette-blue-1,
// .has-palette-blue-1-color {
//    @apply text-blue-1;
// }

// .text-palette-blue-2,
// .has-palette-blue-2-color {
//    @apply text-blue-2;
// }

// .text-palette-orange-1,
// .has-palette-orange-1-color {
//    @apply text-orange-1;
// }

.text-palette-gray-1,
.has-palette-gray-1-color {
   @apply text-gray-1;
}

.text-palette-gray-2,
.has-palette-gray-2-color {
   @apply text-gray-3;
}

.text-palette-gray-3,
.has-palette-gray-3-color {
   @apply text-gray-3;
}

.text-palette-gray-4,
.has-palette-gray-4-color {
   @apply text-gray-4;
}

.text-palette-white,
.has-palette-white-color {
   @apply text-white;
}
