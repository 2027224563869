.wp-block-column {
   .block.checklist-cta {
      width: 100%;
      overflow: hidden;
      display: flex;
      flex-direction: column;

      @screen lg {
         max-width: 75%;

         &.mw-none {
            max-width: none;
         }
      }

      > img {
         max-height: 40px;
         object-fit: contain;
         object-position: left;
         margin-bottom: 10px;
      }

      > .title {
         @apply text-4xl;
         @apply font-semibold;
         @apply font-roboto_slab;
         margin-bottom: 20px;
         overflow-wrap: break-word;
      }

      .description {
         margin-bottom: 20px;
      }

      .checklist {
         margin-bottom: 20px;

         li {
            display: flex;
            align-items: center;
            font-size: 18px;

            i {
               font-size: 20px;
               margin-right: 10px;
            }
         }

         &.bullet {
            position: relative;
            list-style: initial;
            left: 1em;

            li {
               position: relative;

               &::before {
                  content: "•";
                  position: absolute;
                  font-size: 36px;
                  left: -18px;
                  // top: -14px;
               }

               i {
                  display: none;
               }
            }
         }
      }

      .buttons {
         gap: 10px;

         .button {
            padding: 12px 24px;
            border-radius: 3px;

            @apply font-roboto_slab;
            @apply font-semibold;
            @apply hover:opacity-75;
            @apply transition-opacity;
         }
      }
   }

   // Blue 1 BG Variant
   &.has-palette-blue-1-background-color {
      .title,
      .checklist {
         color: #fff;
      }

      .description {
         color: rgb(255 255 255 / 80%);
      }

      .button {
         background-color: #fff;
         @apply text-blue-1;
      }
   }

   // Orange 1 BG variant
   &.has-palette-orange-1-background-color {
      .title,
      .checklist {
         color: #fff;
      }

      .description {
         color: rgb(255 255 255 / 80%);
      }

      .checklist {
         i,
         li::marker {
            color: rgb(255 255 255 / 80%);
         }
      }

      .button {
         background-color: rgb(255 255 255 / 80%);
         @apply text-orange-1;
      }
   }

   // Gray 4 BG variant
   &.has-palette-gray-4-background-color {
      .title {
         @apply text-gray-1;
      }

      .description {
         @apply text-gray-3;
      }

      .checklist {
         @apply text-gray-2;

         i,
         li::marker {
            @apply text-orange-1;
         }
      }

      .button {
         @apply bg-orange-1;
         color: #fff;
      }
   }

   // Blue 1 highlight variant {
   &.has-palette-blue-1-color {
      .block.checklist-cta {
         .title {
            @apply text-blue-1;
         }

         .checklist {
            i,
            li::marker {
               @apply text-blue-1;
            }
         }

         .button {
            @apply bg-blue-1;
            color: #fff;
         }
      }
   }

   // Orange 1 highlight variant {
   &.has-palette-orange-1-color {
      .block.checklist-cta {
         .title {
            @apply text-orange-1;
         }

         .checklist {
            i,
            li::marker {
               @apply text-orange-1;
            }
         }

         .button {
            @apply bg-orange-1;
            color: #fff;
         }
      }
   }
}
