// General
@use "partials/general";
@use "partials/general/wp-block";
@use "partials/general/block";
@use "partials/general/header";
@use "partials/general/footer";

// Blocks
@use "partials/blocks/checklist-cta";
@use "partials/blocks/client-carousel";
@use "partials/blocks/form";
@use "partials/blocks/statistics";
@use "partials/blocks/faq";
@use "partials/blocks/contact-info";
@use "partials/blocks/case";
@use "partials/blocks/card-grid";
@use "partials/blocks/text-grid";

@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css");

body {
   scroll-behavior: smooth;
}
