/* WP Block Overrides */

div.wp-block-columns {
   display: grid;
   gap: 0;
   margin-bottom: 0;

   @apply grid-cols-1;

   .wp-block-column {
      @apply flex;
      @apply w-full;
      @apply h-full;
      @apply items-center;
      @apply justify-center;
      @apply p-8;
      @apply md:p-12;

      &.bottom {
         padding: 0;
         align-items: flex-end;
      }
   }

   @media screen and (max-width: 768px) {
      &.mobile-reversed-order {
         .wp-block-column:first-of-type {
            order: 2;
         }

         .wp-block-column:last-of-type {
            order: 1;
         }
      }
   }

   @screen md {
      @apply grid-cols-2;
   }
}

blockquote.wp-block-quote {
   text-align: center;
   padding-block: 40px;

   cite {
      font-size: 20px;
   }
}
