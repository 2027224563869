.block.statistics {
   padding-block: 30px;

   .statistics {
      gap: 30px;
      @apply flex-col;
      @apply md:flex-row;

      .title,
      .number {
         color: #fff;
         text-align: center;
      }

      .number {
         @apply font-roboto_slab;
         font-size: 40px;
         font-weight: 700;

         &.percentage::after {
            content: "%";
         }
      }

      .title {
         font-size: 20px;
         font-weight: 400;
      }
   }
}
