@use "../variables/color";

.block.case {
   background-color: unset;
   background: var(--bg-img);
   background-size: cover;
   background-position: center;

   @apply py-10;
   @apply md:py-16;

   .quote,
   .cta {
      text-shadow: rgb(0 0 0 / 75%) 0px 0 3px;
      color: #fff;
   }

   .quote {
      @apply text-4xl;
      @apply font-semibold;
      @apply font-roboto_slab;
      margin-bottom: 20px;
   }

   &.bg-palette-orange-1 {
      background: linear-gradient(
            0deg,
            rgba(color.$orange-1, 50%),
            rgba(color.$orange-1, 50%)
         ),
         var(--bg-img) center;
   }

   &.bg-palette-blue-1 {
      background: linear-gradient(
            0deg,
            rgba(color.$blue-1, 50%),
            rgba(color.$blue-1, 50%)
         ),
         var(--bg-img) center;
   }

   &.bg-palette-blue-2 {
      background: linear-gradient(
            0deg,
            rgba(color.$blue-2, 50%),
            rgba(color.$blue-2, 50%)
         ),
         var(--bg-img) center;
   }
}
