.block.client-carousel {
   padding-block: 40px;

   .container {
      overflow: hidden;
      position: relative;

      .title {
         margin-bottom: 30px;

         @apply text-3xl;
         @apply font-roboto_slab;
         @apply font-semibold;
         @apply text-gray-1;
      }

      .carousel {
         animation: scroll 30s linear infinite;
         column-gap: 30px;

         .item {
            display: block;
            height: 50px;

            div {
               display: flex;

               @apply items-center;
               @apply justify-center;
               @apply h-full;

               img {
                  width: 250px;
                  max-width: unset;
                  max-height: 100%;
                  object-fit: contain;
                  // filter: brightness(0) invert(1);
                  // background: #fff;
                  // padding: 5px;
                  // border-radius: 5px;
               }
            }
         }
      }

      &::before,
      &::after {
         content: "";
         height: 50px;
         position: absolute;
         bottom: 0;
         width: 100px;
         z-index: 1;
      }
   }

   &.bg-palette-blue-1 {
      .title {
         color: #fff;
      }
   }

   &.bg-palette-white {
      .container {
         &::before {
            left: 0;
            background: linear-gradient(
               to right,
               rgba(#fff, 100%),
               rgba(255, 255, 255, 0) 100%
            );
         }

         &::after {
            right: 0;
            background: linear-gradient(
               to left,
               rgba(#fff, 100%),
               rgba(255, 255, 255, 0) 100%
            );
         }
      }
   }
}
