.block.contact-info {
   align-self: flex-start;

   .container {
      display: block;

      .branches {
         display: grid;
         gap: 30px;

         .branch {
            display: grid;
            row-gap: 10px;
            padding: 20px;
            border-radius: 10px;
            @apply bg-gray-4;

            iframe {
               border-radius: 10px;
               margin-bottom: 10px;
            }

            .name {
               font-size: 19px;
               font-weight: 500;
               line-height: 2.2em;

               @apply text-blue-1;
               @apply font-roboto_slab;
               @apply font-bold;
            }

            p,
            a {
               i {
                  display: inline-flex;
                  justify-content: center;
                  width: 16px;
                  margin-right: 10px;
               }
            }
         }
      }
   }
}
