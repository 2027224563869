@layer base {
   body {
      @apply font-roboto;
      @apply text-gray-2;

      .site-main {
         padding-top: 115px;

         .content-wrapper {
            .wp-block-heading {
               @apply mx-auto;
               @apply container;
               @apply text-4xl;
               @apply font-semibold;
               @apply font-roboto_slab;
               margin-bottom: 20px;
            }

            .has-text-align-center {
               text-align: center;
            }

            > p,
            cite {
               display: block;

               @apply container;
               @apply mx-auto;
            }
         }
      }
   }

   .wp-block-heading {
      @apply text-blue-1;
   }

   ::selection {
      color: #fff;
      @apply bg-orange-1;
      @apply bg-opacity-75;
   }
}
