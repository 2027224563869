.site-header {
   display: flex;
   position: fixed;
   width: 100%;
   height: 115px;
   padding-block: 20px;
   background-color: #fff;
   z-index: 2;
   @apply px-8;

   .container {
      .custom-logo-link {
         @apply transition-opacity;
         @apply hover:opacity-75;

         .custom-logo {
            width: auto;
            max-height: 50px;

            @screen md {
               max-height: 75px;
            }
         }
      }

      nav {
         > ul {
            display: flex;
            align-items: baseline;
            column-gap: 50px;

            > li {
               @apply font-roboto_slab;
               @apply text-xl;

               a {
                  @apply transition-colors;
               }

               > a {
                  display: inline-block;
                  width: 100%;
               }

               &.menu-item-has-children {
                  position: relative;
                  padding-block: 10px;

                  .sub-menu {
                     display: none;
                     position: absolute;
                     margin-top: 20px;
                     border-radius: 5px;
                     @apply bg-gray-4;

                     li {
                        padding: 10px 20px;
                     }
                  }

                  &.pll-parent-menu-item {
                     min-width: 48px;
                     display: flex;
                     @apply justify-center;

                     > a {
                        display: none;

                        @screen md {
                           display: flex;
                           justify-content: center;
                        }
                     }

                     .sub-menu {
                        > li {
                           display: flex;
                           > a {
                              display: inline-block;
                              width: 16px;
                           }
                        }
                     }
                  }

                  @screen md {
                     &:hover {
                        .sub-menu {
                           display: block;
                           animation: fade-in 0.25s ease-in-out;

                           @keyframes fade-in {
                              0% {
                                 opacity: 0;
                              }

                              100% {
                                 opacity: 1;
                              }
                           }
                        }
                     }
                  }
               }

               &.active {
                  > a {
                     pointer-events: none;
                     @apply text-gray-3;
                  }
               }

               &:not(.active):hover {
                  > a {
                     @apply text-blue-1;
                  }
               }
            }
         }

         @media screen and (max-width: 767px) {
            position: absolute;
            width: 100%;
            bottom: 1px;
            left: 0;
            transform: translateY(100%);
            background-color: #fff;
            border-bottom-left-radius: 15px;
            border-bottom-right-radius: 15px;
            overflow: hidden;
            max-height: 0vh;
            transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
            box-shadow: rgb(99 99 99 / 25%) 0px 5px 5px 0px;

            &.open {
               max-height: calc(100vh - 100px);
               transition-timing-function: ease-in-out;

               @keyframes open {
                  0% {
                     max-height: 0vh;
                  }

                  100% {
                     max-height: calc(100vh - 100px);
                  }
               }
            }

            > ul {
               padding: 20px;
               flex-wrap: wrap;
               row-gap: 25px;

               > li {
                  text-align: center;
                  width: 100%;

                  &.pll-parent-menu-item {
                     padding: 0;

                     > a {
                        display: none;
                     }

                     > .sub-menu {
                        margin-top: 0;
                        display: block;
                        position: static;
                        display: flex;
                        column-gap: 10px;
                     }
                  }
               }
            }
         }
      }

      .nav-toggle {
         width: 60px;
         height: 45px;
         position: relative;
         transform: rotate(0deg) scale(0.65);
         transition: 0.5s ease-in-out;

         span {
            display: block;
            position: absolute;
            height: 7px;
            width: 100%;
            border-radius: 7px;
            opacity: 1;
            left: 0;
            transform: rotate(0deg);
            transition: 0.25s ease-in-out;
            @apply bg-blue-1;

            &:nth-child(1) {
               top: 0;
            }

            &:nth-child(2),
            &:nth-child(3) {
               top: 18px;
            }

            &:nth-child(4) {
               top: 36px;
            }
         }

         &.open {
            span:nth-child(1) {
               top: 18px;
               width: 0%;
               left: 50%;
            }

            span:nth-child(2) {
               transform: rotate(45deg);
            }

            span:nth-child(3) {
               transform: rotate(-45deg);
            }

            span:nth-child(4) {
               top: 18px;
               width: 0%;
               left: 50%;
            }
         }
      }
   }
}
