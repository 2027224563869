.site-footer {
   color: #fff;
   @apply bg-blue-2;

   .container {
      gap: 40px;
      padding-inline: 40px;

      @screen md {
         gap: 60px;
      }

      &:first-of-type {
         padding-block: 40px;
      }

      .logo-about,
      .branches {
         display: grid;
         justify-content: center;
      }

      .logo-about {
         .about {
            @apply font-light;
         }

         img {
            max-height: 60px;
            object-fit: contain;
            object-position: left;
            margin-bottom: 20px;
         }
      }

      .branches {
         .branch {
            display: grid;
            row-gap: 5px;

            .name {
               font-size: 19px;
               font-weight: 500;
               line-height: 2.2em;

               @apply text-orange-1;
               @apply font-roboto_slab;
               @apply font-bold;
            }

            .phone {
               display: block;
            }

            &:not(:last-of-type) {
               margin-bottom: 20px;
            }
         }
      }
   }
}
