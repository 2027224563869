.block.faq {
   padding-inline: 20px;
   @apply md:px-0;

   .container {
      margin-block: 50px;

      > .title {
         @apply text-blue-1;
         @apply text-4xl;
         @apply font-semibold;
         @apply font-roboto_slab;
         @apply text-center;
         margin-bottom: 20px;
      }

      .description {
         margin-bottom: 30px;
         @apply text-center;
      }

      .questions {
         .question {
            .title,
            .answer p {
               padding: 20px;
               @apply bg-gray-4;
            }

            .title {
               display: flex;
               justify-content: space-between;
               align-items: center;
               gap: 20px;
               border-radius: 10px;
               @apply font-semibold;

               i {
                  @apply transition-transform;
               }

               @apply hover:cursor-pointer;
            }

            .answer {
               position: relative;
               top: -10px;
               overflow: hidden;
               max-height: 0;
               border-bottom-left-radius: 10px;
               border-bottom-right-radius: 10px;
               transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
            }

            &.open {
               .title {
                  i {
                     transform: rotate(-90deg);
                  }
               }

               .answer {
                  max-height: 100vh;
                  transition-timing-function: ease-in-out;
               }
            }

            &:not(:last-of-type) {
               margin-bottom: 30px;
            }
         }
      }
   }
}
