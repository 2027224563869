.wp-block-column {
   .gform_wrapper {
      width: 100%;

      @screen lg {
         max-width: 75%;
      }

      &.contact-form_wrapper {
         align-self: flex-start;
      }

      .gform_heading {
         display: none;
      }

      form {
         .gform_fields {
            row-gap: 30px;

            .gfield {
               .gfield_label {
                  @apply text-base;
                  @apply font-normal;
                  @apply font-roboto_slab;

                  > .gfield_required {
                     margin-left: 5px;

                     span {
                        @apply text-base;
                     }
                  }
               }

               .gfield_checkbox {
                  margin-bottom: 20px;
               }

               .gfield_required {
                  @apply text-red-1;
               }

               input[type="text"],
               input[type="tel"],
               input[type="email"],
               textarea {
                  width: 100%;
                  margin-bottom: 20px;
                  border-radius: 4px;
                  border-width: 1px;
                  padding: 14px 30px;
                  outline-width: 1px;
                  @apply border-gray-1;

                  @apply outline-gray-3;

                  &::placeholder {
                     @apply opacity-50;
                     @apply uppercase;
                     @apply tracking-widest;
                  }
               }
            }

            .hidden_label .gfield_label,
            .screen-reader-text {
               clip: rect(1px, 1px, 1px, 1px);
               word-wrap: normal !important;
               border: 0;
               clip-path: inset(50%);
               height: 1px;
               margin: -1px;
               overflow: hidden;
               padding: 0;
               position: absolute;
               width: 1px;
            }

            .gform_validation_container {
               display: none !important;
               left: -9000px;
               position: absolute !important;
            }
         }

         .gfield_checkbox {
            label {
               // color: #fff;
               @apply text-base;

               a {
                  @apply text-red-1;
               }
            }
         }
      }

      input[type="submit"] {
         padding: 12px 24px;
         border-radius: 3px;
         color: #fff;

         @apply bg-orange-1;
         @apply font-roboto_slab;
         @apply font-semibold;
         @apply hover:cursor-pointer;
         @apply hover:opacity-75;
         @apply transition-opacity;
      }
   }

   &.has-palette-blue-1-background-color {
      .gform_wrapper {
         .request-demo-form {
            .gfield {
               .gfield_label {
                  color: #fff;
               }

               input[type="text"],
               input[type="tel"],
               input[type="email"] {
                  border-width: 0;
               }
            }

            .gfield_checkbox {
               label {
                  color: #fff;
               }
            }
         }

         input[type="submit"] {
            @apply bg-blue-3;
            color: #fff;
            margin-right: 20px;
         }
      }
   }
}
